<template>

    <main ref="resumen" id="resumen">
        <div class="py-2"><motivoConsultaShow :idHistoria="idHistoria"></motivoConsultaShow></div>
        <div class="py-2"><examenFisicoShow :idHistoria="idHistoria"></examenFisicoShow></div>
        <div class="py-2"><exploracionFisicaShow :idHistoria="idHistoria"></exploracionFisicaShow></div>
        <div class="py-2"><revision-sistemas-show :id-historia="idHistoria"></revision-sistemas-show></div>
        <div class="py-2"><ordenClinicaShow :idHistoria="idHistoria"></ordenClinicaShow></div>
        <div class="py-2"><formulaMedicaShow :idHistoria="idHistoria"></formulaMedicaShow></div>
        <div class="py-2"><impresionDxShow :idHistoria="idHistoria"></impresionDxShow></div>
        <div class="py-2"><RecomendacionesShow :idHistoria="idHistoria"></RecomendacionesShow></div>
        <div class="py-2"><aiepiShow :idHistoria="idHistoria"></aiepiShow></div>
    </main>

</template>
<script>
import motivoConsultaShow from "../../base/motivoConsulta/motivoConsultaShow";
import examenFisicoShow from "../../base/examenFisico/examenFisicoShow";
import ordenClinicaShow from "../../base/orden/ordenClinicaShow";
import formulaMedicaShow from "../../base/formulaMedica/formulaMedicaShow";
import impresionDxShow from "../../base/impresionDX/impresionDxShow";
import exploracionFisicaShow from "../../base/examenFisico/exploracionFisicaShow";
import RevisionSistemasShow from "@/views/historias/base/baseUrgencia/revisionShow";
import RecomendacionesShow from "../../base/recomendaciones/recomendacionesShow";
import aiepiShow from "../../base/baseAiepi/index/aiepiShow";

export default {
    props: ['idHistoria','idUsuario'],
    components:{ motivoConsultaShow,
    examenFisicoShow,
    ordenClinicaShow,
    formulaMedicaShow,
    impresionDxShow,
    exploracionFisicaShow, RevisionSistemasShow, RecomendacionesShow,aiepiShow },
    data () {
        return {
            imgData : '',
        }
    }
}
</script>
<style scoped>
.resumen-container {
    height:300px;
    overflow-y:scroll
}

</style>